








































import { Messages } from "@/models/enums/messages.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";
import {
  DATE_FORMAT_YYYY_MM,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import { accountingService } from "@/services/accounting.service";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import { useBlob, useDate } from "@/hooks";

@Component
export default class ProfitLossReport extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  DATE_FORMAT_YYYY_MM = DATE_FORMAT_YYYY_MM;
  Messages = Messages;
  form!: WrappedFormUtils;
  page = 1;
  limit = 10;
  params: RequestQueryParamsModel = {};
  totalData = 0;
  loading = {
    download: false,
    print: false,
    loadingBranch: false,
    loadingDownload: false,
  };
  formRules = {
    period: {
      label: "lbl_period",
      name: "period",
      placeholder: "lbl_period_placeholder",
      decorator: [
        "period",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };
  value = [];
  status = "";

  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "ProfitLossReport" });
  }

  handlePanelChange(value) {
    if (value.length < 1) this.status = "error";
    else this.status = "";
    this.value = value;
  }
  handleDownload() {
    this.form.validateFields((err, res) => {
      if (err || this.value.length < 1) {
        if (this.value.length < 1) this.status = "error";
        return;
      }
      const { toDownload } = useBlob();
      const { toStartMonth, toEndMonth } = useDate();
      const params: RequestQueryParamsModel = {
        dateFrom: toStartMonth(this.value[0]).format(),
        dateTo: toEndMonth(this.value[1]).format(),
      };
      this.loading.loadingDownload = true;
      params.params = this.checkParams(res);
      accountingService
        .downloadReportProfitLoss(params)
        .then(response => {
          toDownload(response, "profit_loss.xlsx");
        })
        .finally(() => (this.loading.loadingDownload = false));
    });
  }
  checkValue(value): string {
    if (value && !value.includes("undefined")) {
      return value;
    } else {
      return ",ALL";
    }
  }
  checkParams(res): string {
    let params = "";
    // company is mandatory
    params += this.$store.state.authStore.authData.companyName;
    params += this.checkValue(
      `,${moment(this.value[0]).format(DATE_FORMAT_YYYY_MM)}`
    );
    params += this.checkValue(
      `,${moment(this.value[1]).format(DATE_FORMAT_YYYY_MM)}`
    );

    return params;
  }

  handleReset(): void {
    this.form.resetFields();
  }
}
