var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_profit_loss_report") } },
    [
      _c(
        "a-form",
        {
          attrs: {
            form: _vm.form,
            labelCol: { span: 2 },
            wrapperCol: { span: 8 },
            labelAlign: "left"
          }
        },
        [
          _c(
            "a-form-item",
            {
              attrs: {
                label: _vm.$t(_vm.formRules.period.label),
                required: "",
                help:
                  _vm.status === "error"
                    ? _vm.$t(_vm.Messages.VALIDATION_REQUIRED_ERROR)
                    : "",
                "validate-status": _vm.status
              }
            },
            [
              _c("a-range-picker", {
                attrs: {
                  placeholder: [
                    _vm.$t("lbl_start_date"),
                    _vm.$t("lbl_end_date")
                  ],
                  format: _vm.DATE_FORMAT_YYYY_MM,
                  value: _vm.value,
                  mode: ["month", "month"]
                },
                on: {
                  panelChange: _vm.handlePanelChange,
                  change: _vm.handlePanelChange
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "danger" },
                      on: { click: _vm.handleReset }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.loading.loadingDownload
                      },
                      on: { click: _vm.handleDownload }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }